<!-- Option selection -->
<div *ngIf="_GlobalDataService.Campagne.creationType !== 'chili'" class="option">
  <app-toolbar-title title="Chargement de votre fichier de ciblage via SFTP" type="radio"
    [radioCheck]="_GlobalDataService.Campagne.importOption === 'sftp'" [stateFromInput]="true" icon="information"
    (radioState)="importSelection('sftp')">
    <ng-container tooltip>
      Cette fonction permet de charger vos fichiers de ciblage dans la campagne
      Cap Mailing via SFTP
    </ng-container>
  </app-toolbar-title>
  <app-toolbar-title title="Téléchargement de votre fichier de personnalisation et de ciblage" type="radio"
    [radioCheck]="_GlobalDataService.Campagne.importOption === 'upload'" [stateFromInput]="true" icon="information"
    (radioState)="importSelection('upload')"
    >
    <ng-container tooltip>
      Le fichier attendu doit être en format .CSV encodé UTF8. La première ligne
      de votre fichier doit contenir les noms de colonnes avec comme séparateur
      de colonne le caractère suivant <strong>;</strong> . Votre fichier doit
      également contenir les champs obligatoires suivants ; nom, prénom, les
      champs d’adresses, le code postale et la ville.
    </ng-container>
  </app-toolbar-title>
</div>

<!-- Upload -->
<mat-card *ngIf="_GlobalDataService.Campagne.importOption == 'upload' && this._GlobalDataService.Campagne.creationType !== 'chili'">
  <h2>
    <span>Fichiers de ciblage</span>
    <mat-icon svgIcon="question" [mdePopoverTriggerFor]="appPopover3" mdePopoverTriggerOn="hover"
      #popoverTrigger="mdePopoverTrigger" style="cursor: pointer"></mat-icon>
  </h2>
  <mat-card-content>
    <mat-card-tile class="gtmt-add-file">
      <span>
        {{ _GlobalDataService.csvFileName || "Fichiers de ciblage format .CSV UTF8" }}
      </span>
      <input type="file" id="file-upload-perso-standalone" style="display: none" (change)="uploadFile($event)"
        accept=".csv" />
      <button mat-raised-button color="blue" *ngIf="_GlobalDataService.csvFileName == undefined" (click)="addFile()">
        Ajouter un fichier
        <mat-icon>control_point</mat-icon>
      </button>
      <button mat-raised-button color="blue" *ngIf="_GlobalDataService.csvFileName" (click)="deleteFile()">
        Supprimer le fichier
      </button>
    </mat-card-tile>
    <mat-card-tile class="gtmt-deleted" *ngIf="isDeleted == true" style="font-size: 1.6rem; font-weight: 700">
      Fichier supprimé avec succès !
    </mat-card-tile>
    <div *ngIf="_GlobalDataService.csvHeaders && _GlobalDataService.csvRows">
      <app-tab-visualisation [headers]="_GlobalDataService.csvHeaders" [rows]="_GlobalDataService.csvRows"></app-tab-visualisation>
    </div>

    <!-- Personnalisation -->
    <app-campagne-personnalisation *ngIf="_GlobalDataService.csvFileName"></app-campagne-personnalisation>

  </mat-card-content>
</mat-card>


<!-- iframe Chili -->
<mat-card>
  <mat-card-content class="iframe-chili">
    <app-chili-iframe-personnalisation 
      class="app-chili-iframe-personnalisation" 
      *ngIf="_GlobalDataService.Campagne.creationType === 'chili' && _GlobalDataService.csvFileName"
    ></app-chili-iframe-personnalisation>
    <app-purge-message 
      *ngIf="_GlobalDataService.Campagne.isPurged"
      class="app-purge-message" 
      [message]= "messagePurge"
    ></app-purge-message>
  </mat-card-content>
</mat-card>

<!-- Popover messages -->
<mde-popover #appPopover3="mdePopover" [mdePopoverOverlapTrigger]="false">
  <mat-card class="gtmt-popover-card">
    <mat-card-content> &nbsp; </mat-card-content>
  </mat-card>
</mde-popover>

<!-- Loader overlay on upload -->
<mat-spinner-overlay [overlay]="true" *ngIf="isUploading || isDeleting">
</mat-spinner-overlay>

<!-- Stepper navigation  -->
<app-campagne-stepper-nav
  [prevDisplay]="true"
  [nextDisplay]="true"
  (previous)="goToPrevious()"
  (next)="submit()"
></app-campagne-stepper-nav>
